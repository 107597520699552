/* Dropdown button styling */
.dropdownButton {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.23);
  color: inherit;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 140px;
  padding-right: 8px;
  border-radius: 24px;
}

.dropdownButton:hover {
  background-color: #f0f0f0;
}

/* When an item is selected */
.dropdownButton.selected {
  background-color: #e3f2fd;
  border-color: #90caf9;
  color: #1565c0;
  font-weight: bold;
}

/* Clear (X) button */
.clearButton {
  padding: 2px;
  margin-left: 4px;
}

.clearButton:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* List item hover effect */
.listItem {
  transition: background-color 0.2s ease-in-out;
}

.listItem:hover {
  background-color: #d6d6d6;
}
